<template>
  <div class="bbr-trainer--informations">
    <main-app-bar>
      <template v-slot:title>
        <v-skeleton-loader v-if="isLoading" type="heading" width="500" />
        <span v-else>
          <span v-if="!$attrs.id"> Trainer Details </span>
          <span v-else> {{ selectedTrainer.name }} </span>
        </span>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapGetters } from 'vuex'

export default {
  name: 'TrainerInformations',

  components: {
    MainAppBar,
  },

  computed: {
    ...mapGetters({
      isLoading: 'trainers/isLoading',
      isTrainerLoading: 'trainers/isLoading',
      selectedTrainer: 'trainers/selectedTrainer',
    }),
  },
}
</script>
